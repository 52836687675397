
.edit-price{
  .temp-footer{
      margin: var(--gutter) 0
  }
}
.campaign-column{
  .btn-add{
    padding-left: 0
  }
}
.campaign-stickers {
  @extend .article-stickers;
  //border: solid 1px rgb(191, 191, 191);
  background-color: rgb(252, 252, 252);
  box-shadow: inset 0 0 10px rgba(0,0,0,0.1);
  border-radius: var(--border-radius);
  padding: 10px;
  display: flex;
  gap: 10px;
  li {
    display: inline-block;
  }
  .sticker-image {
    width:40px;
    height: 40px;
    border: none !important;
  }
}

.paging {
  list-style: none;
    li {
      cursor: pointer;
      border: solid 1px black;
      border-radius: 2px;
      padding: 5px;
        display: inline-block;
        margin-right: 5px;
      &.active {
        background-color: black;
        color: white;
      }
    }
  
}
/*

.external-login.winpos {
  margin-top: 10px;
}

.customers-page .container {
  display: flex;
  justify-content: space-between;

  .customer-search-result-container {
    flex:40%;
  }

  .customer-details {
    flex:40%;
    border: solid 1px black;
    padding: 10px;
    ol li span {
      display: block;
    }
  }
}


.orders-page {
  .filters .query-group input {
    width: 500px;
  }
  
  .filters input[type="time"]{
    max-width: 100px;
  }
  .filters input[type="time"][disabled]  {
    opacity:0.5
  }
}

.order-search-result-header-container {
  display: table;
  width: 100%;
  .order-search-result-header {
    display:table-row;
    > * {
      display: table-cell;
      padding: 10px;
    }
  }
}
.order-list {
  display: table;
  width:100%;
  li.order-result{
    display: table-row;
    > * {
      display: table-cell;
      width: auto;
      padding: 10px;
    }
    span.anonymous {
      opacity: 0.5;
    }
  }
}

li.order-item{
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: solid 1px #e0e0e0;
} 

.order-header > span {
    display:block;
}
.customer-search-result-header, .customer-orders-header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: solid 1px #e0e0e0;  
    font-weight: bold;
}
.order-items-header, .customer-list-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: solid 1px #e0e0e0;
}

.order-details-container {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: solid 1px #e0e0e0;
}

.winpos-payment-methods {
  list-style: none;
  .payment-method > * {
    margin-right: 10px;
  }
}
.order-shop-details {
  .contact-information > * {
    display: block;
  }
}*/


