.order-details-container {
    @extend .setting-card;
    display: flex;
    gap: var(--gutter);

    .label-group{
        margin-bottom: 5px;
    }
}
.order-contents, .order-payments{
    @extend .setting-card;
}
.order-contents{
    .article{
        
    }
    .receipe-item{
        .description{
            padding-left: 20px;
            font-weight: normal !important;
        }
        span{
            font-size: 0.85rem;
        }
    }
    .vat-item{
        span{
            padding: 10px 10px;
        }
    }
    .total-sum{
        font-weight: bold;
        font-size: 1.2em;
        margin-top: 10px;
        span{
            padding: 20px 10px;
        }
    }
}

.winpos-payment-methods{
    .payment-method{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        border-bottom: 1px solid #e0e0e0;
        &:last-child{
            border-bottom: none;
        }
    }
}

.setting-card{
   // max-width: 800px;
    background-color: white;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: var(--border-radius);
    margin-bottom: 10px;
    h2{
        margin-top: 0;
    }
    > div{
        //display: flex;
        //flex-flow: column;
        //gap: 10px;
        
    }
    .order-item{
        &.article{
            font-weight: bold;
        }
        &.receipe-item{
            padding-left: 20px;
        }
        
    }
}
.label-group{
    .label{
        font-weight: bold;
        margin-right: 10px;
        &::after{
            content: ":";
        }
    }
}